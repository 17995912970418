import {ConsoleLogger} from "../../console-logger";
import { bootstrap } from "vue-gtag";

export default class {
    constructor(enabled = false) {
        let debugGaAnalytics = true;

        this.$gtag = null;

        this.events = {};
        this.timings = {};
        this.timerStartTimes = {};

        this.enabled = enabled;
        this.logger = new ConsoleLogger('GaAnalytics', 'background: #800080; color: white', null, !debugGaAnalytics)
    }

    setGTag($gtag) {
        this.$gtag = $gtag;
    }

    enable() {
        this.enabled = true;

        this.logger.logMessage('$gtag.optIn()');
        this.$gtag.optIn()

        bootstrap().then((r) => {
            this.logger.logMessage('$gtag.bootstrapped');
        });
    }

    disable() {
        this.enabled = false;

        this.logger.logMessage('$gtag.optOut()');
        this.$gtag.optOut()
    }

    event(event, data) {
        if (!this.enabled) {
            // Do nothing

            this.logger.logMessage(`Not calling event ${event} with data ${JSON.stringify(data)} because gaAnalytics is not enabled`);

            return;
        }

        if (!this.$gtag) {
            console.error(`Could not find $gtag property in gaAnalytics plugin - maybe it has not been set yet`);

            return;
        }

        this.$gtag.event(event, data);

        this.logger.logMessage(`Called event ${event} with data ${JSON.stringify(data)}`);
    }

    time(timingData) {
        if (!this.enabled) {
            // Do nothing

            this.logger.logMessage(`Not calling timing with data ${JSON.stringify(timingData)} because gaAnalytics is not enabled`);

            return;
        }

        if (!this.$gtag) {
            console.error(`Could not find $gtag property in gaAnalytics plugin - maybe it has not been set yet`);

            return;
        }

        this.$gtag.time(timingData);

        this.logger.logMessage(`Called timing with data ${JSON.stringify(timingData)}`);
    }

    timerStart(timerKey) {
        this.timerStartTimes[timerKey] = (new Date()).getTime();
    }

    timerEnd(timerKey) {
        let diffTime = this.timerGetSeconds(timerKey);

        delete this.timerStartTimes[timerKey];

        if (diffTime > 0) {
            this.timings[timerKey](diffTime);
        }
    }

    timerGetSeconds(timerKey) {
        if (!this.timerStartTimes.hasOwnProperty(timerKey)) {
            console.error(`Could not find existing started timer by key ${timerKey}`);

            return -1;
        }

        let currentTime = (new Date()).getTime();
        let diffTime = currentTime - this.timerStartTimes[timerKey];

        return diffTime;
    }
}
